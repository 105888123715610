import { FunctionalComponent, h } from "preact";
import { Router } from "preact-router";

import Home from "./routes/Home/Home";
import PlaidLink from "./routes/PlaidLink/PlaidLink";
import PlaidOauth from "./routes/PlaidOauth/PlaidOauth";

const App: FunctionalComponent = () => {
  return (
    <div id="preact_root">
      <Router>
        <Home path="/home/:dataB64" />
        <PlaidLink path="/plaid-link" />
        <PlaidOauth path="/plaid-oauth" />
        <div default>404</div>
      </Router>
    </div>
  );
};

export default App;
