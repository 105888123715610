import { FunctionalComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { usePlaidLink } from "react-plaid-link";
import axios from "axios";

import { SERVER_ADDRESS } from "../../common/constants";
import { handlePlaidLinkSuccess, parseShopifyPayload } from "../../utils";
import { PostCreatePlaidLinkToken, ShopifyParsedData } from "../../types";
import { CenteredPage, LoadingSpinner } from "../../components";
import { Button } from "../../components/Button";
import { returnToStore } from "../../common/functions";

const PlaidLink: FunctionalComponent = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [plaidLinkToken, setPlaidLinkToken] = useState<null | string>(null);
  const [plaidLinkSuccess, setPlaidLinkSuccess] = useState<boolean>(false);

  const { open } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess: async (public_token, metadata) => {
      await handlePlaidLinkSuccess(public_token, metadata, () =>
        setPlaidLinkSuccess(true)
      );
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const urlSearchParam = new URLSearchParams(window.location.search);
        const dataB64 = urlSearchParam.get("encodedData");
        const shippingHandle = urlSearchParam.get("shippingHandle");

        // Encoded data not provided;
        if (!dataB64 || !shippingHandle) {
          returnToStore();
          return;
        }

        const shopifyData = parseShopifyPayload(dataB64);
        localStorage.setItem("parsedShopifyData", JSON.stringify(shopifyData));
        localStorage.setItem("shippingHandle", shippingHandle);

        // Fetch plaid link token.
        const res = await axios.post<PostCreatePlaidLinkToken>(
          `${SERVER_ADDRESS}/v1/api/payments/create-plaid-link-token`,
          {
            customerId:
              typeof shopifyData.customer === "string"
                ? shopifyData.customer
                : btoa(shopifyData.customer.email),
          }
        );
        if (res.status !== 200) returnToStore();

        const { link_token } = res.data;
        localStorage.setItem("plaidLinkToken", link_token);
        setPlaidLinkToken(link_token);

        setLoading(false);
      } catch (error) {
        console.warn(error);
        returnToStore();
      }
    })();
  }, []);

  if (loading)
    return (
      <CenteredPage>
        <LoadingSpinner />
      </CenteredPage>
    );

  if (plaidLinkSuccess)
    return (
      <CenteredPage>
        <LoadingSpinner label="Redirecting..." />
      </CenteredPage>
    );

  return (
    <CenteredPage>
      <div style={{ opacity: 0 }}>
        <Button
          // Click button and open Plaid Link on mount.
          ref={(el) => {
            if (el) el.base.click();
          }}
          onClick={() => open()}
        >
          Open plaid link
        </Button>
      </div>
    </CenteredPage>
  );
};

export default PlaidLink;
