import { FunctionalComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { usePlaidLink } from "react-plaid-link";
import { returnToStore } from "../../common/functions";
import { CenteredPage, LoadingSpinner } from "../../components";
import { handlePlaidLinkSuccess } from "../../utils";

const PlaidAuth: FunctionalComponent = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [plaidLinkSuccess, setPlaidLinkSuccess] = useState<boolean>(false);

  const { open } = usePlaidLink({
    token: loading ? "" : localStorage.getItem("plaidLinkToken"),
    receivedRedirectUri: window.location.href,
    onSuccess: async (public_token, metadata) => {
      await handlePlaidLinkSuccess(public_token, metadata, () =>
        setPlaidLinkSuccess(true)
      );
    },
  });

  useEffect(() => {
    const serializedShopifyData = localStorage.getItem("parsedShopifyData");
    const plaidLinkToken = localStorage.getItem("plaidLinkToken");

    if (serializedShopifyData === null || plaidLinkToken === null) {
      returnToStore();
      return;
    }

    setLoading(false);
  }, []);

  if (loading)
    return (
      <CenteredPage>
        <LoadingSpinner />
      </CenteredPage>
    );
  if (plaidLinkSuccess)
    return (
      <CenteredPage>
        <LoadingSpinner label="Redirecting..." />
      </CenteredPage>
    );

  return null;
};

export default PlaidAuth;
