import { FunctionalComponent, h } from "preact";

import styles from "./index.scss";

interface Button {
  onClick?: () => void;
  bgColor?: string;
  color?: string;
  disabled?: boolean;
  children: string;
}

export const Button: FunctionalComponent<Button> = ({
  onClick,
  children,
  bgColor,
  color,
  disabled,
}) => {
  return (
    <button
      className={styles.button}
      style={{
        backgroundColor: bgColor,
        color,
        opacity: disabled ? 0.6 : undefined,
      }}
      onClick={() => (onClick !== undefined ? onClick() : null)}
      disabled={disabled}
    >
      <b>{children}</b>
    </button>
  );
};
