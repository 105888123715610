import { ShopifyParsedData } from "../../types";

export const parseShopifyPayload = (base64Data: string): ShopifyParsedData => {
  const decodedParams = decodeURIComponent(atob(base64Data));
  try {
    const parsedParams: ShopifyParsedData = JSON.parse(decodedParams);
    return parsedParams;
  } catch (error: any) {
    throw error;
  }
};
