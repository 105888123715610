import axios from "axios";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { SERVER_ADDRESS } from "../../common/constants";
import { returnToStore } from "../../common/functions";
import { ShopifyParsedData } from "../../types";

export const handlePlaidLinkSuccess = async (public_token: string, metadata: PlaidLinkOnSuccessMetadata, onStart: Function) => {
    try {
        onStart();

        const encodedJSONShopifyData =
          localStorage.getItem("parsedShopifyData");
        const shippingHandle =
          localStorage.getItem("shippingHandle");

        if (encodedJSONShopifyData === null) {
          returnToStore();
          return;
        }

        const parsedShopifyData = JSON.parse(
          encodedJSONShopifyData
        ) as ShopifyParsedData;

        if (parsedShopifyData === undefined) {
          returnToStore();
          return;
        }

        const { customer, products } = parsedShopifyData;

        // Send public_token to server.
        const res = await axios.post(
          `${SERVER_ADDRESS}/v1/api/shopify/create-order`,
          {
            publicToken: public_token,
            products,
            customer,
            accountId: metadata.accounts[0].id,
            shippingHandle
          }
        );

        if (res.status === 200) {
          // Success, redirect.
          if (window !== undefined) {
            window.location.replace(res.data.order_status_url);
          }
        }
      } catch (error) {}
}