import { SHOP_URL } from "../constants";

export const returnToStore = (): void => {
  if (window === undefined) {
    return;
  }

  if (SHOP_URL !== undefined) {
    window.location.replace(SHOP_URL);
  } else {
    window.alert("Error, please return to store and try again.");
  }
};
