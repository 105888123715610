import { FunctionalComponent, h } from "preact";

import styles from './index.scss'


import icon from '../../assets/Rolling-1s-200px.svg';

interface LoadingSpinner {
  label?: string
}

export const LoadingSpinner: FunctionalComponent<LoadingSpinner> = ({ label }) => {
  return <div className={styles.loadingSpinner}>
    <div className={styles.loadingSpinner__indicator}>
      <img src={icon}/>
    </div>
    <span className={styles.loadingSpinner__label}>{ label ?? 'Loading...' }</span>
  </div>;
};