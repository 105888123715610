import { FunctionalComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { route } from "preact-router";
import axios from "axios";

import { parseShopifyPayload } from "../../utils";
import { CenteredPage, LoadingSpinner } from "../../components";
import { returnToStore } from "../../common/functions";
import { SERVER_ADDRESS } from "../../common/constants";

import styles from "./index.scss";
import { Button } from "../../components/Button";
import { ApiShippingMethod } from "../../types";

interface Home {
  dataB64?: string;
}

const Home: FunctionalComponent<Home> = ({ dataB64 }) => {
  const [isCheckingCart, setIsCheckingCart] = useState<boolean>(true);
  const [shippingMethod, setShippingMethod] = useState<string | undefined>();
  const [isFetchingShippingMethod, setIsFetchingShippingMethod] =
    useState(true);
  const [availableShippingMethods, setAvailableShippingMethods] = useState<
    ApiShippingMethod[]
  >([]);

  useEffect(() => {
    if (!dataB64) {
      // Redirect.
      returnToStore();
      return;
    }

    try {
      parseShopifyPayload(dataB64);
      setIsCheckingCart(false);
    } catch (error) {
      console.log(error);
      returnToStore();
    }
  }, []);

  useEffect(() => {
    if (!isCheckingCart) {
      if (!dataB64) {
        // Redirect.
        returnToStore();
        return;
      }

      // Retrieve available shipping methods.
      (async () => {
        const { customer, products } = parseShopifyPayload(dataB64);
        const { data } = await axios.post<ApiShippingMethod[]>(
          `${SERVER_ADDRESS}/v1/api/shopify/shipping-methods`,
          {
            products,
            customer,
          }
        );

        // Store methods in state.
        setAvailableShippingMethods(data);

        // Loading finished.
        setIsFetchingShippingMethod(false);
      })();
    }
  }, [isCheckingCart]);

  const handleRadioChange = (value: string) => {
    console.log({value})
    setShippingMethod(value);
  };

  const handleFormSubmit = () => {
    if (isCheckingCart) return;

    // Redirect to Plaid Link. Pass parameters along.
    route(
      `/plaid-link?encodedData=${dataB64}&shippingHandle=${shippingMethod}`
    );
  };

  if (isCheckingCart || isFetchingShippingMethod) {
    return (
      <CenteredPage>
        <LoadingSpinner />
      </CenteredPage>
    );
  } else {
    return (
      <CenteredPage>
        <div className={styles.shippingMethods}>
          <h2 className={styles.shippingMethods__header}>Shipping method</h2>
          <div className={styles.shippingMethods__container}>
            {availableShippingMethods.length > 0 ? (
              availableShippingMethods.map((sm) => (
                <div
                  className={styles.shippingMethods__optionContainer}
                  key={sm.handle}
                >
                  <input
                    type="radio"
                    id={sm.handle}
                    name={sm.handle}
                    value={sm.handle}
                    checked={sm.handle === shippingMethod}
                    // @ts-ignore
                    onChange={(e) => handleRadioChange(e.target.value)}
                  />
                  <label
                    className={styles.shippingMethods__optionLabel}
                    for={sm.handle}
                  >
                    <span className={styles.shippingMethods__optionName}>
                      {sm.title}
                    </span>
                    <span className={styles.shippingMethods__optionPrice}>
                      ${sm.price.amount}
                    </span>
                  </label>
                </div>
              ))
            ) : (
              <div className={styles.shippingMethods__empty}>
                <span>
                  No shipping methods available. Please go back to a previous
                  page and check whether your address is correct.
                </span>
                <div
                  className={styles.shippingMethods__emptyBack}
                  onClick={(_) => {
                    window.history.back();
                  }}
                >
                  Back to the previous page.
                </div>
              </div>
            )}
          </div>
          <Button
            bgColor="#000"
            color="#fff"
            disabled={shippingMethod === undefined}
            onClick={handleFormSubmit}
          >
            Submit
          </Button>
        </div>
      </CenteredPage>
    );
  }
};

export default Home;
